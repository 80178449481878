<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/offline/stores"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ survey ? survey.name : $t('messages.loading') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ODNSurvey
        v-if="survey"
        :survey="survey"
        :loading="false"
        :offline="true"
        @saved="onSaved"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  toastController,
} from '@ionic/vue';

import ODNSurvey from '@c/odn-survey';

export default {
  name: 'OfflineStoreSurvey',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    ODNSurvey,
  },
  computed: {
    ...mapState('surveys', {
      survey: 'selectedSurvey',
    }),
  },
  methods: {
    async onSaved() {
      const toastSuccess = await toastController.create({
        message: this.$t('messages.surveyResponse.post.success'),
        color: 'success',
        duration: 2000,
      });
      toastSuccess.present();

      this.$router.go(-1);
    },
  },
};
</script>
